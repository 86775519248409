'use video'
import { memo, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { useInView, useDeviceType } from '@/hooks'
import { vw, mq } from '@/styles'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

interface IContentVideo {
  data: any,
}


export const Video = memo(({ data }:IContentVideo) => {
  const { platform, long_video: longVideo, short_video: shortVideo } = data
  const [playing, _setPlaying] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [placeholderImage, setPlaceholderImage] = useState('')
  const { ref } = useInView({
    rootMargin: '0px',
    threshold: 0,
    triggerOnce: true
  })


  const { isMobile } = useDeviceType()
  const url = `https://www.${platform}.com/${platform === 'youtube' ? 'watch?v=' : ''}${longVideo ?? shortVideo}`

  const fetchApiVimeo =  async () => {
    const id = longVideo || shortVideo
    const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://www.vimeo.com/${id}`)
    const dataRes = await response.json()
    setPlaceholderImage(dataRes.thumbnail_url)
  }

  useEffect(() => {
    if(platform === 'vimeo') fetchApiVimeo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ platform])


  return (
    <>
      <StyledFigure ref={ref} className='figure--video'>
        {!isReady &&
         <>
           {placeholderImage ?
           // eslint-disable-next-line @next/next/no-img-element
             <img src={placeholderImage} className='img_placeholder'/>

             :
             <div className='placeholder'></div>
           }
         </>
        }
        <ReactPlayer url={url} playsinline={true} controls={false}  volume={0} muted={true} loop={true} title={''} playing={isMobile ? true: playing} onReady={() => setIsReady(true)}/>
      </StyledFigure>
    </>
  )
})

const StyledFigure = styled.figure<any>`
  position: relative;
  width: 100%;
  grid-column: 1 / span 6;
  grid-row: 3;
  height: 100% !important;
  min-height: 220px;
  width: 100% !important;

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
    min-height: 400px;
  }
  ${mq.greaterThan('desktop-md')} {
    min-height: 700px;
  }

  img {
    width: 100%;
  }

  figcaption {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: 12px;
    font-weight: 400;
    padding-bottom: ${vw(20, 'mobile')};
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')} {
      padding-bottom: ${vw(20, 'desktop')};
      margin-bottom: ${vw(20, 'desktop')};
    }
  }

  > div {
    height: 100%!important;
    width: 100%!important;
  }


  iframe {
    aspect-ratio: 16 / 9;
    height: auto !important;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100% !important;

    ${mq.greaterThan('tablet')} {
      height: auto !important;
      left: unset;
      position: unset;
      transform: none;
      width: 100% !important;
    }
  }

  .placeholder, .img_placeholder {
    background-color: var(--color-black);
    height: 220px;
    width: 100% !important;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 12;
      height: 400px;
    }
    ${mq.greaterThan('desktop-md')} {
      height: 700px;
    }
  }

/*
  iframe {
    aspect-ratio: 16 / 9;
    height: 100% !important;
    width: auto !important;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    ${mq.greaterThan('tablet')} {
      left: unset;
      position: unset;
      transform: none;
      height: auto !important;
      width: 100% !important;
    }
    ${mq.greaterThan('desktop')} {
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
    }

  } */
`

