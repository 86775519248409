'use client'
import { FC, Suspense, useState } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useVideoReady } from '@/context'
import { useDeviceType, useInView, useTranslator } from '@/hooks'
import { mq, vw, designGrid, getP75, getTransition } from '@/styles'
import { RandomWord } from './RandomWord'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

export const HeaderHome:FC = () => {
  const { t } = useTranslator()
  const { videoReady, setVideoReady } = useVideoReady()
  const [doubleCheck, setCheck] = useState(false)
  // const scrollState = useScrollTracker(30)
  const { isMobile } = useDeviceType()

  // const scrollTextVariant = useMemo(() => mediaType === 'mobile' ? scrollTextVariantMobile : scrollTextVariantDesktop, [mediaType])
  const { ref } = useInView({ rootMargin: '0px 0px -100px 0px' })

  return (
    <Header ref={ref} $videoReady={videoReady}>
      <Suspense fallback={null}>
        <ReactPlayer className='react-player' width='100%' height='100%' url={`/videos/DY_Home_animation${isMobile ? '_mobile' : ''}.mp4`} playing={true} playsinline={true} loop={true} muted={true} onReady={() => {setVideoReady(true); setCheck(true)}} />
      </Suspense>
      <AnimatePresence>
        <motion.div className='text-wrapper' variants={variants} initial='initial' animate={videoReady && doubleCheck ? 'animate' : 'initial'} exit='exit'>
          <h1>
            {t('home.header.title')}
            <RandomWord words={t('home.header.title_words')} />
          </h1>
        </motion.div>
      </AnimatePresence>
    </Header>
  )
}

const Header = styled.header<{ $videoReady: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: ${vw(60, 'mobile')};
  min-height: 60vh;
  mix-blend-mode: multiply;
  position: relative;

  ${mq.greaterThan('tablet')} {
    margin-top: ${vw(100, 'desktop')};
    min-height: 70vh;
  }

	.text-wrapper {
    ${designGrid({})}
    align-self: flex-start;
    padding-top: ${vw(22, 'mobile')};
    position: absolute;
    z-index: 2;

    ${mq.greaterThan('tablet')} {
      padding-top: ${vw(76, 'desktop')};
      z-index: 5;
    }
	}

  .react-player {
    ${mq.lessThan('tablet')} {
      padding-top: 200px;
    }
  }

  h1 {
    ${getP75()}
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 5;
    justify-content: flex-start;
    /* position: absolute; */
    text-align: left;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 6;
      grid-row: 1 / span 1;
    }
  }

  img {
    height: 100%;
    margin: 0;
    object-fit: cover;
    width: 100%;
  }

  video {
    outline: 0 !important;
    opacity: ${({ $videoReady }) => $videoReady ? 1 : 0};
    pointer-events: none;
    transition: 100ms opacity linear;
    user-select: none;
  }
`

const variants = {
  initial: {
    y: '-100%',
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [0.6, 0.2, 0.4, 1.5],
      delay: 2
    }
  }
}
