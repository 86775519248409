import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/HeaderHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainHome"] */ "/vercel/path0/src/components/home/MainHome.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/SectionExpertise.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/SectionOurClients.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/SectionProjects.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/Separator.tsx");
