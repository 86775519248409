'use client'
import { FC, memo } from 'react'
import { Separator } from './Separator'
import { SectionProjects } from './SectionProjects'
import { SectionOurClients } from './SectionOurClients'
import { SectionExpertise } from './SectionExpertise'
import { MarqueeSlider } from '../MarqueeSlider'

type Props = {
  data: any[]
}

export const MainHome:FC<Props> = memo(({ data }) => {
  return (
    <main>
      <Separator />
      <SectionProjects data={data} />
      <SectionOurClients />
      <SectionExpertise />
      <MarqueeSlider />
    </main>
  )
})
