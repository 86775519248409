'use client'
import { memo, useEffect } from 'react'
import styled from 'styled-components'
import { useScrollTracker, useInView } from '@/hooks'
import { mq } from '@/styles'

export const Separator = memo(() => {
  const { ref: refInView, inView } = useInView()
  const scrollState = useScrollTracker(0)

  useEffect(() => {
    if (scrollState === 'TOP') document.body.classList.remove('dark-mode')
    else if (!inView && scrollState === 'SCROLLING_DOWN') document.body.classList.add('dark-mode')
    else if (inView && scrollState === 'SCROLLING_UP') document.body.classList.remove('dark-mode')
  }, [inView, scrollState])

  return (
    <Span ref={refInView} > </Span>
  )
})

const Span = styled.span`
  height: 1px;
  background-color: transparent;
  display: block;
  position: relative;
  top: -50px;

  ${mq.greaterThan('tablet')} {
    top: -60px;
  }

   ${mq.greaterThan('desktop-md')} {
    top: -70px;
  }
`
