'use client'
import { useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { getTransition, mq, vw, designGrid, getP40, getP15, getMargin5 } from '@/styles'
import { useInView, useSlider, useTranslator } from '@/hooks'
// import Image from 'next/image'

const preloadImages = (list) => {
  for (var i = 0; i < list.length; i++) {
    var img = new Image()
    const src = `/images/svg/clients/logo--${list[i]}.svg`
    img.src = src
  }
}

const getLogo = (image, idx) => {
  if(!image[idx]) return null
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img key={idx} src={`/images/svg/clients/logo--${image[idx]}.svg`} alt={`${image[idx]} logo`} />
  )
}

export const SectionOurClients = () => {
  const { t } = useTranslator()
  const logos = t('home.section_our_clients.logos')
  const { currentSlide, triggerRef } = useSlider(Array.isArray(logos) ? logos.length : 0, 2000)
  const Logo = useMemo(() => getLogo(logos, currentSlide), [logos, currentSlide])

  // 250
  const { ref:refInView } = useInView({ rootMargin: '-100px 0px 0px 0px' })

  // const refFunc = useCallback((e) => {
  //   if (e) {
  //     if (typeof refInView === 'function') refInView(e)
  //     if (typeof triggerRef === 'function') triggerRef(e)
  //   }
  // }, [])

  useEffect(() => {
    preloadImages(t('home.section_our_clients.logos'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section variants={variants} initial='initial' whileInView='animate' viewport={{ once: true, margin: '-30%' }} ref={(e) => {
      if (typeof refInView === 'function') refInView(e)
      if (typeof triggerRef === 'function') triggerRef(e)
    }}>
      <h2>{t('home.section_our_clients.title')}</h2>
      <div className='p'>
        {t('home.section_our_clients.description')}
        <span className='logos-spacer'></span>
        <span className='logos-wrapper'>
          <AnimatePresence>
            <motion.figure key={currentSlide} variants={imageVariants} initial='initial' exit='exit' animate='animate'>
              {Logo}
            </motion.figure>
          </AnimatePresence>
        </span>
      </div>
    </Section>
  )
}

const Section = styled(motion.section)`
  ${designGrid({})}
  padding: ${vw(150, 'mobile')} 0 ${vw(100, 'mobile')};

  ${mq.greaterThan('tablet')} {
    padding: ${vw(200, 'desktop')} 0 ${vw(150, 'desktop')};
  }

  h2,
  p, .p {
    grid-column: 1 / span 6;
  }

  h2 {
    ${getP15()}
    text-align: left;

    &:last-of-type {
      ${getMargin5()}
    }
  }

  > div {
    grid-column: 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 8;
    }
  }

  p, .p {
    ${getP40()}

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 8;
      overflow: hidden;
    }
  }

  .logos-wrapper {
    display: inline-block;
    height: ${vw(61, 'mobile')};
    min-width:  ${vw(150, 'mobile')};
    overflow: visible;
    pointer-events: none;
    position: relative;

    ${mq.lessThan('tablet')} {
      bottom: ${vw(53, 'mobile')};
      left: ${vw(70, 'mobile')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(40, 'desktop')};
      min-width:  ${vw(150, 'desktop')};
    }

    div {
      width: 100%;
    }

    figure {
      height: ${vw(61, 'mobile')};
      width: 100%;
      position: relative;

      ${mq.greaterThan('tablet')} {
        height: ${vw(61, 'desktop')};
      }
    }
  }

  .logos-spacer {
    display: block;
    width: ${vw(25, 'mobile')};
    margin-top: ${vw(5, 'mobile')};

    ${mq.greaterThan('tablet')} {
      display: inline-block;
      margin-top: 0;
      width: ${vw(25, 'desktop')};
    }
  }
`

const imageVariants: any = {
  initial: {
    opacity: 0,
    y: '-50%',
    position: 'absolute',
    transition: {
      ease: [0.6, 0.2, 0.4, 1.5]
    }
  },
  animate: {
    opacity: 1,
    y: '-5%',
    transition: {
      ease: [0.6, 0.2, 0.4, 1.5]
    }
  },
  exit: {
    y: '50%',
    opacity: 0,
    transition: {
      ease: [0.6, 0.2, 0.4, 1.5]
    }
  },
}

const variants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
    }
  }
}
