'use client'
import { useInView, useTranslator } from '@/hooks'
import styled from 'styled-components'
import { mq, vw, getP15_2, getP30, getP15, getMargin10, designGrid } from '@/styles'

const expertiseArray = [
  'AI strategy',
  'Tech strategy',
  'Gen AI',
  'AI apps',
  'AI automation',
  'AI integrations',
  'Great UX design',
  'Digital product design',
  'Spectacular websites',
  'B2B + B2C ecommerce',
  'MACH architecture',
  'Composable',
  'Headless',
  'React/Next',
  'Custom solutions',
  'BigCommerce',
  'Complex integrations',
  'Custom sites, services and apps'
]

const getExpertiseButtons = (expertiseArray) => expertiseArray.map((content, idx) => {
  return <Button key={idx}>{content}</Button>
})

export const SectionExpertise = () => {
  const { ref: refInView } = useInView({ rootMargin: '100px 0px 0px 0px' })
  const { t } = useTranslator()

  const ExpertiseButtons = getExpertiseButtons(expertiseArray)

  return (
    <SectionStyled ref={refInView}>
      <h2>{t('home.section_expertise.title')}</h2>
      <p>{t('home.section_expertise.description')}</p>
      <div className='expertise'>
        {ExpertiseButtons}
      </div>
      <figure>
        <video preload='auto' autoPlay={true} muted={true} loop={true} playsInline>
          <source src='/videos/expertise-new.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </figure>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-x: hidden;
  padding: ${vw(100, 'mobile')} 0 ${vw(100, 'mobile')};
  position: relative;
  width: 97%;

  ${mq.greaterThan('tablet')} {
    ${designGrid({})}
    padding: ${vw(150, 'desktop')} 0 ${vw(88, 'desktop')};
    width: unset;
  }

  h2,
  p {
    color: white;
    grid-column: 1 / span 6;
    grid-row: auto / span 1;

    ${mq.greaterThan('tablet')} {
      grid-column: 3 / span 8;
    }
  }

  h2 {
    ${getP15()}

    &:last-of-type {
      ${getMargin10()}
    }
  }

  p {
    ${getP30()}
    text-align: center;

    &:last-of-type {
      margin-bottom: ${vw(50, 'mobile')};

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(50, 'desktop')};
      }
    }
  }

  figure,
  .react-player {
    grid-area: 3 / 1 / span 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-area: 3 / 3 / span 1 / span 8;
    }
  }

  figure {
    grid-row: auto / span 1;
    margin-top: ${vw(70, 'mobile')};
    mix-blend-mode: screen;
    position: relative;
    z-index: 5;

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(70, 'desktop')};
    }

    > span {
      position: relative !important;
      margin-bottom: ${vw(100, 'mobile')};

      ${mq.greaterThan('tablet')} {
        margin-bottom: 0;
      }
    }

    img {
      height: 100% !important;
      position: relative !important;
      width: 100% !important;
      scale: 1.25;

      ${mq.greaterThan('tablet')} {
        margin-top: 0;
        scale: 1;
      }
    }
  }

  .expertise {
    column-gap: ${vw(11.5, 'mobile')};
    display: flex;
    flex-flow: wrap;
    grid-column: 1 / span 6;
    grid-row: auto / span 1;
    justify-content: center;
    row-gap: ${vw(14, 'mobile')};

    ${mq.greaterThan('tablet')} {
      column-gap: ${vw(10, 'desktop')};
      grid-column: 2 / span 10;
      padding: 0 2vw;
      row-gap: ${vw(15, 'desktop')};
    }
  }

  video {
    object-fit: contain;
    width: 100%;
  }
`

const Button = styled.button`
  ${getP15_2()}
  border-radius: ${vw(26, 'mobile')};
  border: 1px solid var(--color-white);
  color: var(--color-white);
  display: inline-block;
  flex-shrink: 0;
  height: ${vw(52, 'mobile')};
  padding: ${vw(8, 'mobile')} ${vw(16, 'mobile')};
  pointer-events: none;
  text-align: center;
  width: max-content;

  ${mq.lessThan('tablet')} {
    line-height: ${vw(14, 'mobile')};
  }

  ${mq.greaterThan('tablet')} {
    border-radius: ${vw(26, 'desktop')};
    height: ${vw(52, 'desktop')};
    padding: ${vw(15, 'desktop')} ${vw(25, 'desktop')};
  }
`
