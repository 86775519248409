'use client'
import { memo, type FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import type { TypeCMSImage, TypeCMSVideo } from '@/schema'
import { Video } from './Video'

type TypeSize = { width: number, height: number }
type TypeVectorImage = {
  type?: 'svg'
  src: string,
  alt: string,
  size?: TypeSize,
  content_type?: 'image/svg+xml'
}

type TypeFigure = {
  readonly media?: TypeCMSImage | TypeCMSVideo | TypeVectorImage,
  readonly lazy?: boolean,
  readonly initialInView?: boolean,
  onClick?: any,
  readonly fill?: boolean,
  readonly outRef?: (node?: Element) => void,
  readonly className?: string,
  readonly sizes?: string,
  readonly quality?: number
  readonly ariaHidden?: boolean
  readonly unoptimized?: boolean
}

const FigureStyled = styled.figure`
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    object-fit: cover;
  }
`

function isTypeCMSVideo(media: any): media is TypeCMSVideo {
  return media?.type === 'video'
}

function isTypeVectorImage(media: any): media is TypeVectorImage {
  return media?.type === 'svg'
}

const getMediaVariant = (media) => {
  if(media?.s) return 's'
  else if(media?.m) return 'm'
  else if(media?.l) return 'l'
  else if(media?.sliderS) return 'sliderS'
  else if(media?.sliderM) return 'sliderM'
  else if(media?.sliderL) return 'sliderL'
  return null
}

export const Figure: FC<TypeFigure> = memo(({ media, lazy = true, fill = true, onClick = null, className = '', outRef = null, sizes = '70vw', quality = 75, ariaHidden = false, unoptimized = false}) => {
  if (!media) return <figure className='placeholder' />
  if (isTypeCMSVideo(media)) return <Video data={media} />

  return (
    <FigureStyled className={className} {...onClick && { onClick }}  {...outRef && { ref: outRef }}>
      {isTypeVectorImage(media) ?
        <Image src={media.src} alt={media.alt} fill={fill} aria-hidden={ariaHidden}
          {...!lazy && { priority: true }}
          {...!fill && { height: media.size.height }}
          {...!fill && { width: media.size.width }}
          unoptimized={true}
        />
        :
        <Image
          // placeholder='blur'
          src={media[getMediaVariant(media)]?.src} alt={media?.alt} fill={fill}
          {...!lazy && { priority: true }}
          {...!fill && { height: media[getMediaVariant(media)]?.size.height }}
          {...!fill && { width: media[getMediaVariant(media)]?.size.width }}
          sizes={sizes}
          {...quality !== 75 && { quality }}
          {...unoptimized && { unoptimized: true }}
        />
      }
    </FigureStyled>
  )
})
