'use client'
import { useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { mq, vw } from '@/styles'
import { useInterval } from '@/hooks'

const hiddenDuration = 300
const hiddenDelay = 2700
const intervalDelay = hiddenDuration + hiddenDelay

const getTextDisplayed = (text: string) => {
  if (text.startsWith('AI')) {
    return (
      <>
        <span className='span'>{text}<span className='cursor'></span></span>
        <span className='span'></span>
      </>
    )
  } else {
    const splitIndex = text.indexOf(' ')
    if (splitIndex !== -1) {
      const firstPart = text.substring(0, splitIndex)
      const restPart = text.substring(splitIndex + 1)
      return (
        <>
          <span className='span'>{firstPart}</span>
          <span className='span'>{restPart}<span className='cursor'></span></span>
        </>
      )
    } else {
      return (
        <>
          <span className='span'>{text}<span className='cursor'></span></span>
          <span className='span'></span>
        </>
      )
    }
  }
}

export const RandomWord = ({ words }): JSX.Element => {
  const [visible, setVisible] = useState(true)
  const [charIndex, setCharIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [word, setWord] = useState(words[index])
  const [wordDisplayed, setWordDisplayed] = useState('')

  const textDisplayed = useMemo(() => getTextDisplayed(wordDisplayed), [wordDisplayed])

  const updateWord = () => {
    const incomingIndex = index < words.length - 1 ? index + 1 : 0
    setIndex(incomingIndex)
    setWord(words[incomingIndex])
  }

  const type = () => {
    setWordDisplayed(wordDisplayed + word[charIndex])
    if (charIndex < word.length - 1) {
      setCharIndex(charIndex + 1)
    } else {
      // clear word
      setVisible(false)
      setCharIndex(0)
      updateWord()
    }
  }

  const getRandomNumber = (min = 90, max = 250) => {
    return Math.floor(Math.random() * (max - min)) + min
  }

  useInterval(() => {
    if (visible) {
      type()
    } else {
      setWordDisplayed('')
      setVisible(true)
    }
  }, visible ? getRandomNumber() : intervalDelay)

  return (
    <WordWrapper>
      <Word $visible={visible} variants={variants} initial={'visible'} animate={visible ? 'visible' : 'hidden'}>{textDisplayed}</Word>
    </WordWrapper>
  )
}

const variants = {
  visible: {
    width: 'auto',
    transition: {
      duration: .2,
      type: 'tween',
      ease: [.25, .1, .25, 1],
    }
  },
  hidden: {
    width: 0,
    transition: {
      duration: hiddenDuration / 1000,
      type: 'tween',
      ease: [.25, .1, .25, 1],
      delay: hiddenDelay / 1000
    }
  }
}

const WordWrapper = styled.div`
  display: inline-block;
  background: transparent !important;
  min-height: 200px;
  width: 100%;

  ${mq.greaterThan('tablet')} {
    min-height: ${vw(90, 'desktop')};
    width: auto;
  }

  .cursor {
    background: var(--color-text);
    display: inline-block;
    height: ${vw(47, 'mobile')};
    margin-bottom: -${vw(9, 'mobile')};
    margin-left: 10px;
    margin-top: auto;
    transition: 60ms opacity ease-out;
    user-select: none;
    width: 2px;

    ${mq.greaterThan('tablet')} {
      height: ${vw(68, 'desktop')};
      margin-bottom: -${vw(12, 'desktop')};
    }
  }
`

const Word = styled(motion.div) <{ $visible: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;

  span {
    vertical-align: baseline;
  }

  .span {
    white-space: nowrap;
  }

  .cursor {
    opacity: ${({ $visible }) => $visible ? 1 : 0};
  }
`
